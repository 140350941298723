import React, { Component } from 'react';
import classnames from 'classnames';

import { Link } from 'react-router-dom'

import image1 from '../img/experienced.png'
import image2 from '../img/dedicated.png'
import image3 from '../img/connected.png'

import image4 from '../img/tvz900-compressor.jpg'
import image5 from '../img/trz1000-compressor.jpg'

import Marketing from '../components/Marketing/Marketing'
import HomeProduct from '../components/HomeProduct/HomeProduct'

const title1 = 'We Are Experienced';
const description1 = 'We have a combined experience of nearly 40 years in industrial businesses from minning to manufacturing';
const title2 = 'We Are Dedicated';
const description2 = 'We are young, energetic and driven which guarentees our clients that we will deliver everytime.'
const title3 = 'We Are Connected';
const description3 = 'Our founders have developed relationships with suppliers that will deliver superiour cost savings to our clients.'

const Product1 = 'TVx 900';
const Product1Des = 'TVx 900 series compressors can be constructed as one, two or three stage. A booster variant is available in this series.'
const Product2 = 'TRz 1000';
const Product2Des = 'The new TRZ 1000 compressor- our vertical solution for mass flows of up to 1100 kg/h and volume flows of up to 600 Nm³/h'

class Home extends Component {
    state = {
        animate: false,
    }
    componentDidMount = () => {
        this.setState({
            animate: true,
        })
    }
    render() {
        return (
            <div>
                <div className={classnames('full-height', 'header', 'valign-wrapper')}>
                    <div className="row center-align pt-10" style={{zIndex: 1}}>
                        <h1 className={classnames('white-text','title-animation', {'zoomIn': this.state.animate})}>WE ARE YOUR</h1>
                        <h1 className={classnames('white-text','title-animation', {'slideInUp': this.state.animate})}>PARTNERS IN</h1>
                        <h4 className={classnames('white-text','title-animation', {'slideInUp': this.state.animate})}>INDUSTRIAL PROCUREMENT</h4>
                    </div>
                </div>
                <div className="row py-10 marketing-background">
                    <div className="container">
                        <Marketing 
                            image={image1}
                            title={title1}
                            description={description1}
                        />
                        <Marketing 
                            image={image2}
                            title={title2}
                            description={description2}
                        />
                        <Marketing 
                            image={image3}
                            title={title3}
                            description={description3}
                        />
                    </div>
                </div>
                <div className="row py-10 container">
                    <h5 className="center-align py-5">Some of our products</h5>
                    <HomeProduct 
                        image={image4}
                        title={Product1}
                        description={Product1Des}
                    />
                    <HomeProduct 
                        image={image5}
                        title={Product2}
                        description={Product2Des}
                    />
                    <p className="center-align mt-2"><Link to="/products">Click here</Link> for the full range of products.</p>
                </div>
                <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d532.084106699208!2d28.048614460821877!3d-26.21550870250968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950e939163c6e1%3A0xb5548a2c7bd3ca50!2sVillage+Industrial+Park%2C+51+Rosettenville+Rd%2C+Village+Main%2C+Johannesburg%2C+2001!5e0!3m2!1sen!2sza!4v1557160006564!5m2!1sen!2sza" width="100%" height="300" frameborder="0" style={{border:0}} allowfullscreen></iframe>
                </div>
            </div>
        )
    }
}

export default Home;