import React, { Component } from 'react';

import {Icon} from 'react-materialize'

class Footer extends Component {
    render() {
        return (
            <footer className="page-footer brand-color">
                <div className="container">
                    <div className="row">
                        <div className="col l9 s12">
                            <h5 className="white-text">Contact Us</h5>
                            <div className="col l6 s12 left-align text-align">
                                <p className="grey-text text-lighten-4 footer-text">Village Industrial Park</p>
                                <p className="grey-text text-lighten-4 footer-text">Unit A6</p>
                                <p className="grey-text text-lighten-4 footer-text">51 Rosttenville Rd</p>
                                <p className="grey-text text-lighten-4 footer-text">Village Main</p>
                                <p className="grey-text text-lighten-4 footer-text">Johannesburg 2001</p>
                            </div>
                            <div className="col l6 s12 text-align left-align">
                                <p className="grey-text text-lighten-4 footer-text valign-wrapper">
                                <i className="fas fa-phone"></i>
                                    &nbsp;+27 11 338 6008
                                </p>
                                <p className="grey-text text-lighten-4 footer-text valign-wrapper">
                                <i className="fas fa-envelope"></i>
                                    <span>
                                        &nbsp;contact@p3projects.co.za
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col l3 s12 center-align">
                            <h5 className="white-text">Connect with us:</h5>
                            <ul className="social-icons">
                                <li><a className="social-icon facebook" href="#!" data-aos="zoom-in" data-aos-easing="ease-in" data-aos-delay="50"><i className="fab fa-facebook"></i></a></li>
                                <li><a className="social-icon instagram" href="#!" data-aos="zoom-in" data-aos-easing="ease-in" data-aos-delay="150"><i className="fab fa-instagram"></i></a></li>
                                <li><a className="social-icon twitter" href="#!" data-aos="zoom-in" data-aos-easing="ease-in" data-aos-delay="250"><i className="fab fa-twitter"></i></a></li>
                                <li><a className="social-icon linkedin" href="#!" data-aos="zoom-in" data-aos-easing="ease-in" data-aos-delay="350"><i className="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright brand-color-darken">
                    <div className="container">
                        © 2019 Copyright Phuma Phambili Procurement and Projects.
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;