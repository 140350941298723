import React, { Component } from 'react';

import TitleHeader from '../components/TitleHeader/TitleHeader'

import HeaderImage from '../img/about.jpg'

const Heading = 'About'


class About extends Component {
    render() {
        return (
            <div>
                <TitleHeader 
                    image={HeaderImage}
                    heading={Heading}
                />
                <div className="container py-10">
                <h4>Who we are</h4>
                <p>We are your partners in industrial procurement. Phuma Phambili was founded by business owners for business owners. We know the struggles of business procurement particularly in finding trustworthy suppliers and trying to balance cost with quality. We provide the services we wished were available for our businesses.</p>
                <h4>What we do</h4>
                <p>We partner with your business and learn everything we can about your both vision and direction for your business. We then help you on your path by eliminating the stressful process of finding suppliers and procuring services and products. In a nutshell, we have developed a smarter way of delivering products and services that are essential to your product as well as your product process.</p>
                <h4>How we do it</h4>
                <p>Our network of suppliers and partners span across multiple continents and countries. Over the course of our managements own careers in various industries from mining to manufacturing, they have developed relationships with their own suppliers. These relationships in different aspects of the procurement process like shipping, supply chain, customs, excise, product and supplier vetting as well as quality assurance have allowed us to negotiate on behalf of our clients. Aside from cost savings, peace of mind is pretty much guaranteed so that you have more time managing and growing your business.</p>
                <h4>Who we do it for</h4>
                <p>Any company who has or does not wish to go through the process of switching or taking on new suppliers and products. Allow us to investigate, test and procure your products and services before you take the plunge. Our network is vast and we carry a range of agencies.</p>
                </div>
                
            </div>
        )
    }
}

export default About;