import React, { Component } from 'react';
import cx from 'classnames'

export default class ProductSlider extends Component {
    state = {
        category: true
    }
    componentWillMount () {
        if (this.props.category === undefined) {
            this.setState({
                category: false,
            })
        }
    }
    render() {
        return (
            <div className={cx({'py-5':!this.state.category})}>
                {this.props.category?<h4 className="py-5">{this.props.category}</h4>:''}
                {this.props.description?<p className="py-3">{this.props.description}</p>:''}
                <div className={cx("slider-container")}>
                    <div className="slider-item" style={{backgroundImage: `url(${this.props.imageOne})`}}>
                        <p style={{zIndex: 1, position: 'relative'}} className="white-text">{this.props.titleOne}</p>
                    </div>
                    <div className="slider-item" style={{backgroundImage: `url(${this.props.imageTwo})`}}>
                        <p style={{zIndex: 1, position: 'relative'}} className="white-text">{this.props.titleTwo}</p>
                    </div>
                    <div className="slider-item" style={{backgroundImage: `url(${this.props.imageThree})`}}>
                        <p style={{zIndex: 1, position: 'relative'}} className="white-text">{this.props.titleThree}</p>
                    </div>
                    <div className="slider-item" style={{backgroundImage: `url(${this.props.imageFour})`}}>
                        <p style={{zIndex: 1, position: 'relative'}} className="white-text">{this.props.titleFour}</p>
                    </div>
                    {this.props.imageFive?<div className="slider-item" style={{backgroundImage: `url(${this.props.imageFive})`}}>
                        <p style={{zIndex: 1, position: 'relative'}} className="white-text">{this.props.titleFive}</p>
                    </div>:''}
                </div>
            </div>
            
        )
    }
}