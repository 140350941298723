import React, { Component } from 'react';

import TitleHeader from '../components/TitleHeader/TitleHeader'
import ProductSlider from '../components/ProductSlider/ProductSlider'

import HeaderImage from '../img/products.jpg'

import image1 from '../img/TEL.jpg'
import image2 from '../img/TEW.jpg'
import image3 from '../img/TRX200.jpg'
import image4 from '../img/TZL.jpg'
import image5 from '../img/TZW50.jpg'
import image6 from '../img/TRX500.jpg' // TRX300
import image7 from '../img/TRX700.jpg'
import image8 from '../img/TVX900.jpg'
import image9 from '../img/TRX400.jpg'
import image10 from '../img/TRZ1000.jpg'
import image11 from '../img/MRX300.jpg'
import image12 from '../img/MRX400.jpg'
import image13 from '../img/MRX500.jpg'
import image14 from '../img/MRX1300.jpg'

// Contsants
const Heading = 'Products';

const categoryOne = 'Mehrer Air products'
const descriptionOne = 'Piston Compressors'
const descriptionTwo = 'Diaphragm Compressors'


class Products extends Component {
    render() {
        return (
            <div>
                <TitleHeader 
                    image={HeaderImage}
                    heading={Heading}
                />
                <div className="container py-5">
                    <ProductSlider 
                        category={categoryOne}
                        description={descriptionOne}
                        imageOne={image1}
                        titleOne='TEL series'
                        imageTwo={image2}
                        titleTwo='TEW series'
                        imageThree={image3}
                        titleThree='TRX200 series'
                        imageFour={image4}
                        titleFour='TZL series'
                        imageFive={image5}
                        titleFive='TZW50 series'
                    />
                    <ProductSlider 
                        
                        imageOne={image6}
                        titleOne='TRX300 series'
                        imageTwo={image7}
                        titleTwo='TRX700 series'
                        imageThree={image8}
                        titleThree='TVX900 series'
                        imageFour={image9}
                        titleFour='TRX400 series'
                        imageFive={image10}
                        titleFive='TRZ1000 series'
                    />
                    <ProductSlider 
                        description={descriptionTwo}
                        imageOne={image11}
                        titleOne='MRx 300 / MLx 300 / MHx 300'
                        imageTwo={image12}
                        titleTwo='MRx 400 / MLx 400 / MHx 400'
                        imageThree={image13}
                        titleThree='MRx 500 / MHx 500'
                        imageFour={image14}
                        titleFour='MRx 1300 / MHx 1300'
                        /* imageFive={image5}
                        titleFive='TZW50 series' */
                    />
                </div> 
            </div>
        )
    }
}

export default Products;