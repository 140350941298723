import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';

import './App.css';

// Components
import NavMenu from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

// views
import Home from './views/Home';
import About from './views/About';
import Products from './views/Products';
import Services from './views/Services';
import Industries from './views/Industries';

// Styles
import M from 'materialize-css';
import AOS from 'aos';
import 'aos/dist/aos.css'

function App() {
  AOS.init()
  M.AutoInit();
  return (
    <div className="App">
    <HashRouter >
      <NavMenu />
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route path='/products' component={Products}/>
        <Route path="/services" component={Services}/>
        <Route path="/industries" component={Industries}/>
        <Route path="/about" component={About}/>
      </Switch>
      <Footer />
    </HashRouter>
    </div>
  );
}

export default App;
