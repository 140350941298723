import React, { Component } from 'react';

import cx from 'classnames'

class TitleHeader extends Component {
    render() {
        return (
            <div className={cx('header-image')} style={{backgroundImage: `url(${this.props.image})`}}>
                <h1 className={cx('center-align pt-10 white-text', 'mt-0')} style={{zIndex: 1}}>{this.props.heading}</h1>
            </div>
        )
    }
}

export default TitleHeader;