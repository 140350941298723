import React, { Component } from 'react';

import TitleHeader from '../components/TitleHeader/TitleHeader'
import HeaderImage from '../img/services.jpg'

const Heading = 'Services'


class Services extends Component {
    render() {
        return (
            <div>
                <TitleHeader 
                    image={HeaderImage}
                    heading={Heading}
                />
                <div className="full-height"></div>
            </div>
        )
    }
}

export default Services;