import React, { Component } from 'react';

import TitleHeader from '../components/TitleHeader/TitleHeader'
import HeaderImage from '../img/industries.jpg'

const Heading = 'Industries'


class Industries extends Component {
    render() {
        return (
            <div>
                <TitleHeader 
                    image={HeaderImage}
                    heading={Heading}
                />
                <div className="full-height"></div>
            </div>
        )
    }
}

export default Industries;