import React, { Component } from 'react';

import cx from 'classnames';

export default class HomeProduct extends Component {
    render() {
        return (
            <div className={cx('col s6 center-align mb-2')} data-aos="fade-up" data-aos-easing="ease-in" data-aos-delay="350">
                <img src={this.props.image} alt="" className={cx('home-product-image')}></img>
                <h5>{this.props.title}</h5>
                <p>{this.props.description}</p>
            </div>
        )
    }
}