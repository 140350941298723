import React, { Component } from 'react';


import cx from 'classnames';

class Marketing extends Component {
    render() {
        return (
            <div className={cx('col s4 center-align py-5')} data-aos="zoom-in" data-aos-easing="ease-in" data-aos-delay="150">
                <img className={cx('marketing-image')} alt="" src={this.props.image}></img>
                <h4>{this.props.title}</h4>
                <p>{this.props.description}</p>
            </div>
        )
    }
}

export default Marketing